#page-contact,
#page-contact * {
    box-sizing: border-box;
    --content-height: 72px;
    --content-spacer: 45px;
    --page-spacer: 300px;
}
#page-contact {
    margin: 0 0 var(--page-spacer);
}
#page-contact p {
    margin: 0 0 var(--content-spacer);
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
}
#page-contact form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--container-grid-spacer);
}
#page-contact .form-group {
    margin-bottom: 0;
}
#page-contact .form--title {
    grid-column: span 2;
    display: block;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
}
#page-contact .form--atendimento {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    margin: 0;
}
#page-contact .form--atendimento .form-check-label {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-main);
}
#page-contact .form--assunto,
#page-contact .form--nome,
#page-contact .form--email,
#page-contact .form--mensagem,
#page-contact .form--optin {
    grid-column: span 2;
}
#page-contact .form--optin .form-check {
    margin: 0 0 15px;
}
#page-contact .form--optin .form-check label {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-main);
    margin-top: 2px;
    margin-left: 6px;
}
#page-contact .form--optin .form-check label a {
    text-decoration: underline;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-main);
}
#page-contact .contact-box {
    width: 100%;
    margin-top: calc(0px - var(--content-height) - var(--content-spacer));
    padding: 30px 70px 50px;
    background: var(--color-lightest);
}
#page-contact .contact-box .contact-item {
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 50px;
    padding: 50px 0;
}
#page-contact .contact-box .contact-item .title {
    display: block;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2em;
    color: var(--color-darker-alt);
}
#page-contact .contact-box .contact-item a {
    display: block;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2em;
    color: var(--color-darker-alt);
}
#page-contact .contact-box .contact-item .info {
    display: block;
    margin: 8px 0 0;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.2em;
    color: var(--color-darker-alt);
}
#page-contact .contact-box .contact-item .content {
    position: relative;
}
#page-contact .contact-box .contact-item .content:before {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    top: -50px;
    left: 0;
    background: var(--color-main);
}
#page-contact .contact-box .contact-item:first-of-type .content:before {
    display: none;
}

@media(max-width: 992px) {
    #page-contact,
    #page-contact * {
        --page-spacer: 100px;
    }
    #page-contact form {
        grid-template-columns: 1fr;
    }
    #page-contact .form--phone,
    #page-contact .form--cep,
    #page-contact .form--cidade,
    #page-contact .form--pais,
    #page-contact .form--estado {
        grid-column: span 2;
    }
    #page-contact p {
        margin: 0 0 20px;
        font-size: 1.125rem;
    }
    #page-contact .contact-box {
        margin-top: 0;
        padding: 0 20px;
        margin-bottom: 45px;
    }
    #page-contact .contact-box .contact-item .title,
    #page-contact .contact-box .contact-item a {
        font-size: 1.125rem;
    }
    #page-contact .contact-box .contact-item .info {
        font-size: 0.875rem;
    }
    #page-contact .contact-box .contact-item {
        column-gap: 30px;
        padding: 30px 0;
    }
    #page-contact .contact-box .contact-item .content:before {
        top: -30px;
    }
}